import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Styleguide" />
    <h1>Styleguide</h1>
    <p>Welcome to the styleguide</p>
    <ul uk-tab="tab-menu">
      <li className="uk-active"><a href="#left">Left</a></li>
      <li><a href="#item1">Item</a></li>
      <li><a href="#item2"> Item</a></li>
      <li className="uk-disabled"><a href="#disabled">Disabled</a></li>
    </ul>
    <div className="tab-menu-content uk-switcher uk-margin">
      <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-active">
        <h4 className="uk-margin-small">Today • 1pm </h4>
        <h3 className="uk-card-title uk-margin-small">All Alaskan Racing Pigs</h3>
        <p className="small uk-margin-remove">Barn M</p>
        <p className="small uk-margin-remove">RODEO • Featured, Ticket</p>
      </div>
      <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-switcher">
        <h3 className="uk-card-title">Second tab card</h3>
      </div>
      <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-switcher">
        <h3 className="uk-card-title">Third tab card</h3>
      </div>
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
